<template>
    <div>
        <b-overlay :show="loadGraph" rounded="sm">

            <div class="container_site">
                
                <div class="container_dimension_mobile__card_segments--mobile">
                    <div class="container_dimension_mobile__card_segment--title_segment">
                        {{ Mapa.name}} 
                    </div>
                    <div class="container_dimension_mobile__card_segment--description_segment">
                        <!-- {{Graph.description || Mapa.description}} -->
                    </div>
                    <Maps 
                        :type="Mapa"
                        
                    />
                </div>
                <div class="container_btn_actions mb-5">
                    <div class="container_btn_actions__btn_back" @click="$router.go(-1)">
                        <img src="@/assets/img/icono-regresar.svg" alt="">
                        <p>Regresar</p>
                    </div>
                    <div class="container_btn_actions__btn_home" @click="$router.push({name: 'Home'})">
                        <img src="@/assets/img/icono-inicio.svg" alt="">
                        <p>Inicio</p>
                    </div>
                </div>
                
            </div>
        </b-overlay>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loadGraph: true,
            Mapa: {
                name:'',
                description:'',
                url:''
            }
        }
    },

    created() {
        this.Mapa.name=this.$route.query.name
        this.Mapa.url=this.$route.query.url
        this.loadGraph= false
    }, 
}
</script>